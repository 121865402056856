import { Component, Input } from '@angular/core';

import { IconFamily } from './icon.type';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
})
export class IconComponent {
  @Input() iconName?: string;
  @Input() iconFamily?: IconFamily = 'solid';
  @Input() iconSize!: string;
  @Input() customIconPath?: string;
}
