<div class="d-flex align-items-center justify-content-center">
  <ng-container *ngIf="iconName">
    <i
      [ngStyle]="{ width: iconSize, height: iconSize }"
      [ngClass]="['fa-' + iconFamily, 'fa-' + iconName]"
      [style.font-size]="iconSize"
    ></i>
  </ng-container>

  <ng-container *ngIf="!iconName && customIconPath">
    <img [src]="customIconPath" alt="" />
  </ng-container>
</div>
